import { render, staticRenderFns } from "./GameOverScreen.vue?vue&type=template&id=534d4024&scoped=true&"
import script from "./GameOverScreen.vue?vue&type=script&lang=js&"
export * from "./GameOverScreen.vue?vue&type=script&lang=js&"
import style0 from "./GameOverScreen.vue?vue&type=style&index=0&id=534d4024&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "534d4024",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('534d4024')) {
      api.createRecord('534d4024', component.options)
    } else {
      api.reload('534d4024', component.options)
    }
    module.hot.accept("./GameOverScreen.vue?vue&type=template&id=534d4024&scoped=true&", function () {
      api.rerender('534d4024', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/routes/GameOverScreen.vue"
export default component.exports